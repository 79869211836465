import MyFooter from "@/components/MyFooter";
export default {
  name: 'MyHome',

  data() {
    return {
      ua: ''
    };
  },

  components: {
    MyFooter
  }
};